@import '../../scss/theme-bootstrap';

@if $cr22 == true {
  .mpp-container {
    position: relative;
    &--has-filters,
    &--has-sort {
      padding-top: 60px;
    }
    &__overlay {
      overflow: hidden;
      body {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          #{$ldirection}: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $color-gray-op60;
          z-index: 995;
          opacity: 0.75;
          cursor: pointer;
        }
      }
    }
    .mpp-filter-set__container {
      min-height: auto;
      border: 0;
      width: 100%;
      overflow: hidden;
      padding: 0;
      @include breakpoint($medium-up) {
        height: auto;
        min-height: auto;
        top: -1px;
      }
      &--inner.loading {
        width: 100%;
        #{$ldirection}: 0;
        position: relative;
      }
      &.sticky {
        height: 70vh;
        @include breakpoint($medium-up) {
          height: auto;
        }
      }
      .sticky-header-opened & {
        padding-top: 0;
      }
    }
    .mpp-container__controls {
      @include transition(transform $sticky-animation-duration $sticky-animation-timing-function);
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      display: block;
      padding: 10px 0;
      border-top: 1px solid $color-gray-op15;
      border-bottom: 1px solid $color-gray-op15;
      height: 60px;
      width: 100%;
      max-width: $max-width-xlarge;
      background-color: transparent;
      @include breakpoint($medium-up) {
        padding: 10px 20px;
        margin: 0 auto;
      }
      @include breakpoint($large-up) {
        padding: 10px 40px;
      }
      &.sticky {
        top: 0;
        transform: translateY(84px);
        background: $color-white;
        @include breakpoint($large-up) {
          transform: translateY(97px);
        }
        .mpp-filter-set {
          &__header {
            height: 60px;
            padding: 10px 20px;
            @include breakpoint($medium-up) {
              padding: 10px 40px;
            }
          }
          &__footer {
            margin-bottom: 0;
            @include breakpoint($medium-up) {
              position: static;
              margin: 30px 0;
            }
          }
          .mpp-filter-set__container {
            width: 100%;
            height: 70vh;
            min-height: auto;
            top: -1px;
            bottom: unset;
            margin: auto;
            padding: 0;
            border-top: 1px solid $color-gray-op15;
            @include breakpoint($medium-up) {
              height: auto;
            }
          }
        }
      }
      .mpp-filter-cta--close,
      .mpp-product-count {
        @include typography-body-text;
        top: 20px;
      }
      .mpp-filter-set__body {
        @include breakpoint($medium-up) {
          padding: 20px 0;
          margin: 0 40px;
        }
      }
      .mpp-filter-cta--close {
        #{$rdirection}: 20px;
        width: auto;
        @include breakpoint($medium-up) {
          #{$rdirection}: 40px;
          width: auto;
        }
      }
      &--sort {
        .mpp-sort-menu,
        .selectBox-label {
          width: auto !important;
          min-width: auto;
        }
      }
      .mpp-filter-cta,
      .mpp-sort-menu.selectBox {
        font-family: $bbtext-regular;
        color: $color-core-black;
        text-transform: none;
        border: none;
        width: auto;
        padding-#{$ldirection}: 20px;
        background-color: transparent;
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 0;
        }
        .selectBox-arrow {
          width: 30px;
          height: 40px;
          background-color: transparent;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
            transform: none;
          }
        }
      }
      .mpp-sort-menu.selectBox {
        text-align: $rdirection;
        float: $rdirection;
        margin-#{$rdirection}: 10px;
        padding-#{$ldirection}: 0;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 0;
        }
      }
      &--filter {
        margin-#{$ldirection}: 0;
      }
      .mpp-sort-menu--arrow-down {
        display: none;
      }
      &.full-page-takeover {
        z-index: 999;
        top: 0;
      }
      &--product-count {
        @include typography-body-text;
        position: relative;
        display: flex;
        height: 40px;
        align-items: center;
        float: $rdirection;
        color: $color-core-black;
        white-space: pre-wrap;
        &::after {
          content: '';
          height: 20px;
          margin: 0 10px;
          border-#{$rdirection}: 2px solid $color-gray-op10;
        }
        .mpp-product-count-product-suffix,
        .mpp-product-count-products-suffix {
          display: inline-block;
        }
      }
    }
    .mpp-grid,
    .product-grid {
      &__wrapper {
        margin: 0;
        position: relative;
      }
      &__item {
        padding: 30px 20px;
        margin: 0;
        @include breakpoint($medium-up) {
          border-#{$rdirection}: 1px solid $color-gray-op15;
          padding: 0 20px 60px;
          &:nth-child(2n) {
            border: none;
          }
        }
        @include breakpoint($large-up) {
          &:nth-child(2n) {
            border-#{$rdirection}: 1px solid $color-gray-op15;
          }
          &:nth-child(4n) {
            border: none;
          }
        }
      }
      &--carousel {
        .mpp-grid__item,
        .product-grid__item {
          @include breakpoint($medium-up) {
            &:nth-child(2n) {
              border-#{$rdirection}: 1px solid $color-gray-op15;
            }
          }
          @include breakpoint($large-up) {
            &:nth-child(4n) {
              border-#{$rdirection}: 1px solid $color-gray-op15;
            }
          }
        }
        .slick-list {
          margin-#{$rdirection}: 1px;
        }
      }
    }
    .mpp-filter-set {
      background: transparent;
      &__body {
        @include breakpoint($medium-up) {
          border-top: 1px solid $color-gray-op15;
          padding-top: 20px;
          padding-#{$ldirection}: 40px;
          display: flex;
        }
      }
      &__section {
        @include breakpoint($medium-up) {
          position: relative;
          flex: 0 1;
          flex-basis: 50%;
          &::before {
            content: '';
            position: absolute;
            #{$rdirection}: 40px;
            width: 1px;
            height: 100%;
            background: $color-gray-op15;
          }
          &:last-child,
          &:nth-child(2n) {
            &::before {
              display: none;
            }
          }
        }
        @include breakpoint($large-up) {
          flex-basis: 25%;
          &:nth-child(2n) {
            &::before {
              display: block;
            }
          }
          &:nth-child(4n) {
            &::before {
              display: none;
            }
          }
        }
      }
      &__filters {
        padding: 20px;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 10px 20px 20px 0);
          margin-#{$rdirection}: 40px;
        }
      }
      &__title {
        @include typography-body-text;
        margin: 0 20px;
        padding: 30px 0 15px;
        text-transform: none;
        color: $color-core-black;
        border: none;
        @include breakpoint($medium-up) {
          margin: 0;
          padding: 20px 0 15px;
          border-top: 0;
        }
        & ~ .mpp-filter-set__filters  {
          @include swap_direction(padding, 0 0 20px 0);
          margin: 0 20px;
          border-bottom: 1px solid $color-line;
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 0 40px 0 0);
            @include swap_direction(padding, 0 20px 20px 0);
            border: none;
          }
        }
        &.collapsed {
          @include icon('arrow_down', 'after');
          position: relative;
          #{$rdirection}: 0;
          padding: 30px 0;
          line-height: 1.3;
          border-bottom: 1px solid $color-line;
          @include breakpoint($medium-up) {
            padding: 20px 0 15px;
            cursor: auto;
            border: none;
          }
          &::after {
            font-size: 10px;
            top: 35px;
            #{$rdirection}: 0;
            @include breakpoint($medium-up) {
              display: none;
            }
          }
          & ~ .mpp-filter-set__filters {
            @include breakpoint($medium-up) {
              display: block;
            }
            &.collapsed {
              @include swap_direction(padding, 0 0 20px 0);
              margin: 0 20px;
              @include breakpoint($medium-up) {
                @include swap_direction(margin, 0 40px 0 0);
                @include swap_direction(padding, 0 20px 20px 0);
                border: none;
              }
            }
          }
          &.opened {
            @include icon('arrow_up', 'after');
            padding: 30px 0 15px;
            border: none;
            @include breakpoint($medium-up) {
              padding: 20px 0 15px;
            }
            &::after {
              font-size: 10px;
              top: 35px;
              #{$rdirection}: 0;
              @include breakpoint($medium-up) {
                display: none;
              }
            }
          }
        }
      }
      &__button {
        @include typography-body-text;
        @include swap_direction(padding, 10px 0 10px 25px);
        border: 0;
        height: auto;
        margin-bottom: 0;
        color: $color-gray-on-light;
        &::before {
          position: absolute;
          content: '';
          #{$ldirection}: 0;
          top: 11px;
          width: 18px;
          height: 18px;
          border: 1px solid $color-gray-on-light;
        }
        &.active {
          color: $color-core-black;
          font-weight: 400;
          &::before {
            border: 1px solid $color-core-black;
            background: $color-core-black;
          }
          &::after {
            display: none;
          }
        }
      }
      &__header {
        height: 60px;
        padding: 10px 20px;
        border-bottom: 1px solid $color-line;
        @include breakpoint($medium-up) {
          padding: 10px 40px;
          border: none;
        }
        .mpp-filter-title {
          @include typography-body-text;
          padding: 0;
          position: relative;
          text-transform: none;
          cursor: pointer;
          color: $color-core-black;
          &::after {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
            transform: rotate(180deg);
            margin-#{$ldirection}: 8px;
          }
        }
      }
      &__footer {
        margin-bottom: 0;
        @include breakpoint($medium-up) {
          margin: 30px 0;
          padding-#{$rdirection}: 40px;
        }
      }
      &__footer--cta {
        display: block;
        text-align: $rdirection;
        padding: 0;
        &-submit {
          margin: 0;
          width: 50%;
          display: inline-block;
          padding: 20px;
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
        &-reset {
          @include typography-link-text;
          width: 48%;
          display: inline-block;
          text-align: center;
          cursor: pointer;
          color: $color-gray-on-light;
          @include breakpoint($medium-up) {
            padding-#{$rdirection}: 20px;
            width: auto;
          }
        }
      }
    }
    .mpp-filter-cta {
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
        margin-#{$ldirection}: 8px;
      }
      &__icon {
        display: none;
      }
    }
    .mpp-sort-menu-selectBox-dropdown-menu {
      width: 100%;
    }
    .mpp-container__content {
      margin-top: 0;
      max-width: $max-width-xlarge;
      background-color: transparent;
      @include breakpoint($medium-up) {
        margin: 30px auto 0;
      }
      @include breakpoint($large-up) {
        padding: 0 20px;
      }
    }
    &.dark-background {
      .mpp-sort {
        color: $color-white;
      }
      .mpp-container {
        &__controls {
          border-color: $color-white-op15;
          .mpp-filter-cta,
          &--product-count {
            color: $color-white;
            &::after {
              border-color: $color-white;
              opacity: 0.1;
            }
          }
          &--sort {
            .mpp-sort-menu,
            .selectBox-label {
              color: $color-white;
            }
          }
          &.sticky {
            .mpp-sort {
              color: $color-core-black;
            }
            .mpp-filter-cta {
              color: $color-core-black;
            }
            .mpp-container__controls {
              &--product-count {
                color: $color-core-black;
                &::after {
                  border-color: $color-gray-op10;
                }
              }
              &--sort {
                .mpp-sort-menu,
                .selectBox-label {
                  color: $color-core-black;
                }
              }
            }
          }
        }
      }
      .mpp-grid__item,
      .product-grid__item {
        border-color: $color-white-op15;
      }
    }
  }
  nav.breadcrumbs {
    max-width: $max-width-xlarge;
    background-color: transparent;
    @include breakpoint($medium-up) {
      padding: 10px 40px;
    }
    @include breakpoint($xxxlarge-up) {
      margin: auto;
    }
    .breadcrumbs__level {
      @include typography-body-text;
      color: $color-core-black;
      text-align: $rdirection;
      a {
        @include typography-body-text;
        color: $color-core-black;
      }
    }
    .breadcrumb__level--count {
      min-width: 100px;
    }
  }
  .mpp-sort-menu-selectBox-dropdown-menu {
    &.selectBox-dropdown-menu {
      #{$ldirection}: 0 !important;
      #{$rdirection}: 0 !important;
      width: 100% !important;
      border: 0;
      max-width: $max-width-xlarge;
      max-height: 305px;
      margin: 0 auto;
      padding: 10px 20px 30px;
      @include breakpoint($medium-up) {
        padding: 10px 40px 30px;
      }
      &.sort-sticky {
        max-width: $max-width-xlarge;
        top: 0 !important;
        padding: 10px 20px 30px;
        width: 100% !important;
        transform: translateY(84px);
        position: fixed;
        border-top: 1px solid $color-gray-op15;
        @include breakpoint($medium-up) {
          padding: 10px 40px 30px;
        }
        @include breakpoint($large-up) {
          transform: translateY(97px);
        }
      }
    }
    &.selectBox-options {
      a {
        @include typography-headline--m;
        text-transform: none;
        color: $color-gray-on-light;
        height: auto;
        padding: 10px 0;
        &:hover {
          color: $color-core-black;
        }
      }
      li {
        position: relative;
        &:first-child {
          a {
            @include typography-body-text;
            display: flex;
            align-items: center;
            height: 40px;
            color: $color-core-black;
            padding: 0;
            margin-bottom: 10px;
            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              #{$rdirection}: 8px;
              width: 12px;
              height: 12px;
              background: url('#{$base-theme-path}svg-icons/src/icon--close.svg') center no-repeat;
              cursor: pointer;
              pointer-events: none;
            }
            &::after {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
              transform: rotate(180deg);
              margin-#{$ldirection}: 8px;
            }
          }
        }
        &.selectBox-selected {
          border: 0 !important;
        }
        &.selectBox-hover {
          a {
            color: $color-core-black;
            .ada-enabled & {
              @include outline;
            }
          }
        }
      }
    }
  }
  .menu-mpp {
    margin-bottom: 10px;
    .anchored-menu-reference {
      .menu {
        &__list {
          display: inline-block;
          width: 95%;
          border-bottom: 1px solid $color-gray-op15;
          margin-#{$ldirection}: 30px;
          text-align: $ldirection;
          padding-#{$ldirection}: 0;
          .filter-fixed-top & {
            border: none;
          }
        }
        &__item {
          &:first-child {
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  .site-header-formatter {
    &--hidden {
      .selectBox-dropdown-menu.sort-sticky,
      .mpp-container__controls.sticky {
        transform: translateY(0);
      }
    }
  }
}
